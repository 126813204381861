<script>
import { Modal, Form, Switch } from 'ant-design-vue'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    getDataAPI: {
      type: Function
    },
    updateDataAPI: {
      type: Function
    }
  },
  data() {
    return {
      show: this.value,
      openScore: false,
      productLikeState:false,
      evaluateState:false,
      params: {}
    }
  },
  watch: {
    value(val) {
      this.show = val
    },
    show(val) {
      this.$emit('input', val)
    }
  },
  mounted() {
    if (this.getDataAPI) {
      this.getDataAPI().then(res => {
        this.openScore = res.openScore
        this.productLikeState = res.productLikeState
        this.evaluateState = res.evaluateState
        this.params = res
      })
    }
  },
  methods: {
    onConfirm() {
      if (this.updateDataAPI) {
        this.updateDataAPI({
          ...this.params,
          openScore: this.openScore,
          productLikeState:this.productLikeState ? '1' : '0',
          evaluateState:this.evaluateState ? '1' : '0'
        }).then(res => {
          this.$message.success('设置成功')
          this.show = false
        })
      }
    }
  },
  render() {
    return (
      <Modal
        title="评分设置"
        v-model={this.show}
        onOk={this.onConfirm}
        onCancel={() => {
          this.show = false
        }}
      >
        <Form>
          <Form.Item
            label="评分/评论"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
          >
            <Switch v-model={this.openScore} />
          </Form.Item>
          <Form.Item
            label="订单评价按钮/我的评价"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
          >
            <Switch v-model={this.evaluateState} />
          </Form.Item>
           <Form.Item
            label="猜你喜欢"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
          >
            <Switch v-model={this.productLikeState} />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}
</script>
<style lang="less" scoped></style>
