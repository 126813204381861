<script>
import { Modal, Form, Switch } from 'ant-design-vue'
import FormUpload from '@/components/FormView/FormUpload'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    getDataAPI: {
      type: Function,
    },
    updateDataAPI: {
      type: Function,
    },
  },
  data() {
    return {
      show: this.value,
      params: {
        img: '',
      },
    }
  },
  watch: {
    value(val) {
      this.show = val
      if (val) {
        if (this.getDataAPI) {
          this.getDataAPI().then((res) => {
            this.params = res
          })
        }
      }
    },
    show(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    onConfirm() {
      if (this.updateDataAPI) {
        this.updateDataAPI({
          ...this.params,
        }).then((res) => {
          this.$message.success('设置成功')
          this.show = false
        })
      }
    },
  },
  render() {
    return (
      <Modal
        title="风格样式"
        v-model={this.show}
        onOk={this.onConfirm}
        onCancel={() => {
          this.show = false
        }}
      >
        <Form>
          <Form.Item label="底图设置" labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}>
            <FormUpload
              item={{}}
              style={{
                width: '100px',
                height: '100px',
                transformOrigin: 'top',
              }}
              value={this.params.img}
              onChange={(data) => {
                this.params.img = data
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  },
}
</script>
<style lang="less" scoped></style>
