<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ...api.command.getState(),
    }
  },
  watch: {},
  computed: {},
  mounted() {
   
    api.command.getList.call(this, {
      url: '/farmRecommendData/page',
      params: {
        recommendId: this.data.id,
      },
      current: 1,
    })
  },
  methods: {
    sumbit() {
      this.$emit('success', this.records)
    },
    getColumns() {
      return [
        {
          dataIndex: 'busName',
          align: 'left',
          title: this.data.moduleCode === 'farm_scenic_ticket' ? '景点名称' : '商品名称',
          width: '30%',
        },
        {
          dataIndex: 'shopName',
          align: 'left',
          width: '30%',
          title: '店铺名称',
        },
        {
          dataIndex: 'sort',
          align: 'left',
          title: '优先级',
          width: 80,
          customRender: function (text, records, index) {
            const onInput = (data) => {
              records.sort = data.target.value
            }
            return <a-input placeholder="优先级" type="number" onChange={onInput} value={text} />
          },
        },
        {
          dataIndex: 'a8',
          title: '操作',
          width: 60,
          type: 'buttonGroup',
          typeData: ({ records, index }) => {
            return [
              {
                name: '删除',
                display: true,
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, { url: `/farmRecommendData/del/${records.id}` })
                },
              },
            ]
          },
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        ref="table"
        showRowSelect={false}
        tableColumns={this.getColumns()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.template-card {
  min-height: 0;
}
/deep/ .header-box {
  margin-left: 0;
}
/deep/.ant-table-body {
  max-height: 500px;
}
</style>
