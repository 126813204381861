<script>
import api from '@/command/api'
import { Input, Select } from 'ant-design-vue'

import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import DrawerFormModal from '@/components/DrawerFormModal'
import apiTool from '@/command/apiTool'
import AddProduct from './AddProduct'
import { getAction, postAction, putAction } from '@/command/netTool'
import { getProductTypeList } from '@/api/command'
import ScoreModal from '@/components/ScoreModal'
import StyleModal from '@/components/StyleModal'
export default {
  name: 'scenic-areas',
  data() {
    return {
      ...api.command.getState(),
      showModal: false,
      formDetail: {},
      farmRecommendModuleList: [],
      baseModuleList: [],
      selectedArr: [],
      selectedArr2: [],
      module: {},
      scenicId: '',
      listParams: {
        total: 1,
      },
      list: [],
      // 评分设置弹窗
      showScoreModal: false,
      // 风格设置弹窗
      showStyleModal: false,
    }
  },
  mounted() {
    this.getBaseModuleList()
    api.command.getList.call(this, {
      url: '/farmTicketScenic/page',
      current: 1,
    })
  },
  methods: {
    async getBaseModuleList() {
      getProductTypeList().then((data) => (this.baseModuleList = data))
    },
    getHeader() {
      return [
        {
          name: '景点名称',
          key: 'name',
          type: 'input',
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'mainBodyName',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'name',
          title: '景点名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.previewImg} />
                <div>{text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.productName.length - b.productName.length,
        },
        {
          dataIndex: 'linkShopName',
          title: '店铺名称',
          align: 'left',
          sorter: (a, b) => a.linkShopName.length - b.linkShopName.length,
        },
        {
          dataIndex: 'readCount',
          title: '访问量',
          align: 'left',
          sorter: (a, b) => a.readCount - b.readCount,
        },
        {
          dataIndex: 'saleCount',
          title: '销量',
          align: 'left',
          sorter: (a, b) => a.saleCount - b.saleCount,
        },
        {
          dataIndex: 'hxCount',
          title: '核销量',
          align: 'left',
          sorter: (a, b) => a.hxCount - b.hxCount,
        },
        {
          dataIndex: 'allStock',
          title: '库存',
          align: 'left',
          sorter: (a, b) => a.allStock - b.allStock,
        },
        {
          dataIndex: 'unUsedStock',
          title: '剩余库存',
          align: 'left',
          sorter: (a, b) => a.unUsedStock - b.unUsedStock,
        },
        {
          dataIndex: 'sort',
          title: '排序',
          align: 'left',
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          fixed: 'right',
          width: 200,
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                dispaly: true,
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/farmTicketScenic/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                name: '门票管理',
                dispaly: true,
                onClick: () =>
                  this.$router.push(
                    `/commodityManagement/scenicAreasTicket?id=${records.id}&shopId=${records.linkShopId}`
                  ),
              },
              {
                name: '周边推荐',
                dispaly: true,
                onClick: () => {
                  this.farmRecommendModuleList = []
                  // this.formDetail = records.data
                  this.showModal = true
                  this.scenicId = records.id
                  this.getfarmRecommendModule()
                },
              },
              {
                name: '编辑',
                dispaly: true,
                onClick: () => this.$router.push(`/commodityManagement/scenicAreasDetail?id=${records.id}`),
              },
              {
                name: '删除',
                type: 'pop',
                dispaly: records.upDown == 1,
                popTitle: '是否确认删除?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmTicketScenic?id=${records.id}`,
                  }),
              },
            ].filter((e) => e.dispaly)
          },
        },
      ]
    },
    onSubmit(data) {
      putAction('/farmRecommendModule/updateBatch', this.farmRecommendModuleList).then((e) => {
        this.$message.success('编辑成功')
        this.showModal = false
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/scenicAreasDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmTicketScenic/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmTicketScenic/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmTicketScenic/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
        {
          name: '页面设置',
          type: 'primary',
          ghost: true,
          onClick: () => this.$router.push('/commodityManagement/scenicAreasPageSetDetail'),
        },
        {
          name: '评分设置',
          type: 'primary',
          ghost: true,
          onClick: () => {
            this.showScoreModal = true
          },
        },
        {
          name: '风格样式',
          type: 'primary',
          ghost: true,
          onClick: () => {
            this.showStyleModal = true
          },
        },
      ]
    },
    getfarmRecommendModule() {
      getAction('/farmRecommendModule/list?scenicId=' + this.scenicId).then((res) => {
        this.farmRecommendModuleList = res.data
      })
    },
    onAddCustom() {
      this.moduleData = {}
      apiTool.showModal({
        width: '380px',
        title: '添加模块',
        dataSource: this.moduleData,
        success: ({ data, setHidden }) => {
          const params = {
            ...this.moduleData,
            showName: '',
            scenicId: this.scenicId,
          }

          postAction('/farmRecommendModule/add', params).then((res) => {
            if (res.code == 200) {
              this.getfarmRecommendModule()
              setHidden()
            } else {
              this.$message.error('保存失败！')
            }
          })
        },
        form: [
          {
            name: '模块',
            type: 'select',
            cols: 24,
            key: 'moduleCode',
            typeData: this.baseModuleList,
            onChange: (val) => {
              this.moduleData.moduleName = this.baseModuleList.find((f) => f.value === val)?.name || ''
              this.moduleData.moduleCode = val
            },
            rules: [
              {
                required: true,
                type: 'string',
                message: '请选择模块',
              },
            ],
          },
        ],
      })
    },
    getFormData() {
      return [
        {
          form: [
            {
              type: 'table',
              wrapperCol: 24,
              dataSource: this.farmRecommendModuleList,
              showPagination: false,
              tableType: 'editDelete',
              showFootButton: true,
              havedrag: true,
              onAddCustom: this.onAddCustom,
              columns: [
                {
                  dataIndex: 'a1',
                  align: 'left',
                  title: '拖动排序',
                  width: 100,
                  customRender: function () {
                    return <a-icon type="menu" />
                  },
                },
                {
                  dataIndex: 'moduleName',
                  align: 'left',
                  title: '模块',
                  width: 160,
                },
                {
                  title: '板块名称',
                  align: 'center',
                  dataIndex: 'showName',
                  customRender: (text, records) => {
                    return (
                      <Input
                        value={text}
                        onInput={(e) => {
                          records.showName = e.target.value
                        }}
                      />
                    )
                  },
                },
                {
                  dataIndex: 'a8',
                  title: '操作',
                  width: 180,
                  type: 'buttonGroup',
                  typeData: ({ records, index }) => {
                    return [
                      {
                        name: '商品列表',
                        display: true,
                        onClick: () => {
                          this.list = []
                          this.showAddProduct(records || {})
                        },
                      },
                      {
                        name: '添加商品',
                        display: true,
                        onClick: () => {
                          this.selectedArr = []
                          if (!records.moduleCode) {
                            this.$message.warning('请先选择模块!')
                            return
                          }
                          getAction('/farmRecommendData/queryRecommendProductList?recommendId=' + records.id).then(
                            (res) => {
                              this.showProductList(res.data, records)
                            }
                          )
                        },
                      },
                      {
                        name: '删除',
                        display: true,
                        type: 'pop',
                        popTitle: '确认是否删除吗?',
                        onClick: () => {
                          api.command.del
                            .call(this, {
                              url: `/farmRecommendModule/del/${records.id}`,
                            })
                            .then((res) => {
                              this.getfarmRecommendModule()
                            })
                        },
                      },
                    ]
                  },
                },
              ],
            },
          ],
        },
      ]
    },
    showAddProduct(records) {
      apiTool.showModal({
        width: '800px',
        title: '商品列表',
        view: AddProduct,
        viewProps: {
          data: records,
        },
        success: ({ data, setHidden }) => {
          putAction('/farmRecommendData/updateBatch', data).then((res) => {
            if (res.code == 200) {
              setHidden()
            } else {
              this.$message.error('保存失败！')
            }
          })
        },
      })
    },
    showProductList(dataSource, records) {
      const that = this
      apiTool.showModal({
        width: '800px',
        title: '添加商品',
        success: ({ data, setHidden }) => {
          this.selectedArr
          const params = {
            recommendId: records.id,
            recommendDataList: this.selectedArr.map((e) => ({
              busId: e,
            })),
          }
          postAction('/farmRecommendData/addBatch', params).then((res) => {
            if (res.code == 200) {
              this.$message.success('保存成功！')
              setHidden()
            } else {
              this.$message.error('保存失败！')
            }
          })
        },
        form: [
          {
            type: 'table',
            wrapperCol: { span: 24 },
            showRowSelect: true,
            showFootButton: false,
            bordered: true,
            rowKey: 'id',
            showPagination: false,
            selectedRowKeys: this.selectedArr,
            selectedRows: this.selectedArr2,
            onAddCustom: (selectedRowKeys) => {
              this.selectedArr = selectedRowKeys
            },
            columns: [
              {
                dataIndex: 'name',
                align: 'left',
                title: records.moduleCode === 'farm_scenic_ticket' ? '景点名称' : '商品名称',
                width: '60%',
              },
              {
                dataIndex: 'linkShopName',
                align: 'left',
                width: '30%',
                title: '店铺名称',
                customRender: function (text, records, index) {
                  return records.linkShopName || records.mainBodyName
                },
              },
            ],
            dataSource: dataSource,
          },
        ],
      })
    },
    renderModal() {
      return (
        <div>
          <ScoreModal
            getDataAPI={() => {
              return getAction('/config/farmConfigHome/detail', {}, '/operation').then((res) => {
                const data = res.data

                return {
                  ...data,
                  openScore: data.scenicScoreState == '1',
                }
              })
            }}
            updateDataAPI={(params) => {
              const { openScore } = params

              return postAction(
                '/config/farmConfigHome/update',
                { ...params, scenicScoreState: openScore ? '1' : '0' },
                '/operation'
              )
            }}
            v-model={this.showScoreModal}
          />
        </div>
      )
    },
    renderStyleModal() {
      return (
        <div>
          <StyleModal
            getDataAPI={() => {
              return getAction('/farmGoodsStyleConfig/getDetailByFromType', { fromType: '12' }, '/operation').then(
                (res) => {
                  const data = res.data || { img: '', fromType: '12' }
                  return {
                    ...data,
                  }
                }
              )
            }}
            updateDataAPI={(params) => {
              return postAction('/farmGoodsStyleConfig/save', { ...params }, '/operation')
            }}
            v-model={this.showStyleModal}
          />
        </div>
      )
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          records={this.records}
        />
        <DrawerFormModal
          visible={this.showModal}
          title="周边推荐"
          width={'800px'}
          onClose={() => (this.showModal = false)}
          onSubmit={this.onSubmit}
          data={this.getFormData()}
          form={this.formDetail}
        ></DrawerFormModal>
        {this.renderModal()}
        {this.renderStyleModal()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
